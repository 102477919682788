














































































































































































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import IconPeople from '@/app/ui/assets/menu_icon_user_management.vue'
import IconCourier from '@/app/ui/assets/icon_motorcycle_front.vue'
import IconPhone from '@/app/ui/assets/icon_phone.vue'
import IconMap from '@/app/ui/assets/menu_icon_pickup_location.vue'
import IconCancelTask from '@/app/ui/assets/icon_cancel_task.vue'
import IconClipboard from '@/app/ui/assets/icon_clipboard.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import IconWarning from '@/app/ui/assets/icon_warning_circle.vue'
import IconClock from '@/app/ui/assets/icon_clock_fill.vue'
import DateTimePicker from '@/app/ui/components/DateTimePicker/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import { EnumPickupDay } from '@/app/infrastructures/misc/Constants/pickup'
import { Route } from 'vue-router'
import {
  ReschedulePickupCourier,
  ReschedulePickupDetail,
} from '@/domain/entities/ReschedulePickup'
import controller, {
  ReschedulePickupPayloadInterface,
} from '@/app/ui/controllers/ReschedulePickupController'
import dayjs from 'dayjs'
import dayjsID from 'dayjs/locale/id'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import { Utils } from '@/app/infrastructures/misc'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import pickupController, {
  DropdownValue,
} from '@/app/ui/controllers/PickupController'
import ModalAction from '@/app/ui/components/Modal/ModalAction.vue'
import IconLocationPin from '@/app/ui/assets/ics_f_location_pin_alt.vue'

interface FormReshcedule {
  day: Date | string
  courier: DropdownValue | string
}

@Component({
  components: {
    IconPeople,
    IconCourier,
    IconPhone,
    IconMap,
    IconCancelTask,
    IconClipboard,
    DropdownSelect,
    IconWarning,
    IconClock,
    DateTimePicker,
    Button,
    ImageViewer,
    LoadingOverlay,
    ModalAction,
    IconLocationPin,
  },
})
export default class ReschedulePickupEdit extends Vue {
  controller = controller
  pickupController = pickupController
  showImage = false
  EnumPickupDay = EnumPickupDay
  imageSrc = ''
  pathBack = ''
  indexOfImage = 0
  dataReschedulePickup: ReschedulePickupDetail = new ReschedulePickupDetail()
  lastCourier: ReschedulePickupCourier = new ReschedulePickupCourier()
  assignmentCourier: ReschedulePickupCourier = new ReschedulePickupCourier()
  rescheduleDay = ''
  minDateTime = ''
  formReshcedule: FormReshcedule = {
    day: '',
    courier: '',
  }
  beforeClickTimePicker = true
  invoiceImage: string[] = []
  showModal = {
    confirm: false,
    success: false,
    connection: false,
  }

  created(): void {
    dayjs.extend(isTomorrow)
    controller.get(this.$route.params.rescheduleId)
  }

  mounted(): void {
    window.addEventListener('offline', this.onHandleOffline)
  }

  get formattedSchedule(): string {
    return Utils.formatTimeZone(
      Utils.formatDateWithIDLocale(
        <string>this.dataReschedulePickup.rescheduledAt,
        'DD MMM YYYY HH:mm Z'
      )
    )
  }

  get formattedModelTimePicker(): string {
    return dayjs(this.formReshcedule.day).format('HH:mm')
  }

  get formattedCurrentDate(): string {
    return dayjs(this.formReshcedule.day).format('DD MMM YYYY')
  }

  get courierOptions(): DropdownValue[] {
    return pickupController.courierData
  }

  get isCourierLoading(): boolean {
    return pickupController.isCourierLoading
  }

  get formattedTimeZone(): string {
    return Utils.formatTimeZone(
      Utils.formatDate(new Date().toDateString(), 'Z')
    )
  }

  private removeDatePicker(): void {
    const el = this.$el.querySelector(
      '#reschedule-picker-picker-container-DatePicker'
    ) as HTMLInputElement
    const elPicker = this.$el.querySelector(
      '.datepicker.flex.flex-direction-column'
    ) as HTMLInputElement
    if (el?.style || elPicker?.style) {
      el.style.display = 'none'
      elPicker.style.width = '160px'
      elPicker.style.minWidth = '160px'
    }

    if(this.rescheduleDay === EnumPickupDay.TODAY) {
      this.minDateTime = dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    } else {
      this.minDateTime = ''
    }

    this.beforeClickTimePicker = false
  }

  private formattedDay(str: string): string {
    if (str === EnumPickupDay.TODAY) {
      return 'Hari Ini • '
    } else if( str === EnumPickupDay.TOMORROW) {
      return 'Besok • '
    } else {
      return ''
    }
  }

  private openMaps(lat: string, long: string): void {
    window.open(`https://maps.google.com?q=${lat},${long}`, '_blank')?.focus()
  }

  private onHandleOffline(): void {
    if (controller.isLoading) {
      controller.setLoading(false)
      this.showModal.connection = true
    }
  }

  private onShowImage(index: number): void {
    this.imageSrc = this.invoiceImage[index]
    this.showImage = true
    this.indexOfImage = index
  }

  private courierSearch = Utils.debounce((search: string) => {
    this.fetchCourierList(search)
  }, 500)

  private fetchCourierList(search?: string) {
    if (search === '') search = undefined
    pickupController.getCourierList({
      perPage: 30,
      q: search,
      courierStatus: 'ACTIVE',
    })
  }

  private onClickRequestSchedule() {
    const payload: ReschedulePickupPayloadInterface = {
      shipmentId: this.dataReschedulePickup.shipmentId,
      courierId: (<DropdownValue>this.formReshcedule.courier).value,
      rescheduledAt: dayjs(this.formReshcedule.day).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      timeZone: dayjs()
        .locale(dayjsID)
        .format('Z'),
    }

    controller.update(payload)
  }

  beforeRouteEnter(
    _: Route,
    from: { path: string; name: string },
    next: (arg0: (vm: { pathBack: string }) => void) => void
  ): void {
    next((vm: { pathBack: string }) => {
      if (from.name === 'ReschedulePickupDetail') {
        vm.pathBack = from.path
      } else {
        vm.pathBack = '/pickup/CRRNFD/1'
      }
    })
  }

  @Watch('controller.dataReschedulePickup')
  onWatchDataReschedulePickup(data: ReschedulePickupDetail): void {
    this.dataReschedulePickup = data
    this.lastCourier = <ReschedulePickupCourier>data.lastCourier
    this.assignmentCourier = <ReschedulePickupCourier>data.assignCourier
    this.formReshcedule.day = dayjs(data.rescheduledAt).format('YYYY-MM-DD[T]HH:mm:ss.SSS')

    const isToday = dayjs().isSame(dayjs(data.rescheduledAt), 'day')
    const isTomorrow = dayjs(data.rescheduledAt).isTomorrow()
    if(isToday) this.rescheduleDay = EnumPickupDay.TODAY
    if(isTomorrow) this.rescheduleDay = EnumPickupDay.TOMORROW
    if(!isToday && !isTomorrow) this.beforeClickTimePicker = false

    if (data.assignCourier?.courierId) {
      const courierName = `[${data.assignCourier.courierId}] ${data.assignCourier.fullname} (${data.assignCourier.courierType}) ${data.assignCourier.phoneNumber}`
      this.formReshcedule.courier = {
        label: courierName,
        value: data.assignCourier.courierId,
      }
    }

    if (!data.meta?.proofs) {
      return
    } else if (data.meta?.proofs && data.meta?.proofs.length <= 4) {
      this.invoiceImage = data.meta.proofs
    } else {
      this.invoiceImage = data.meta.proofs.filter((_, index) => index < 4)
    }
  }

  @Watch('controller.isApproved')
  onWatchErrorRequest(value: boolean): void {
    if (value) {
      this.showModal.success = true
      controller.setIsApproved(false)
    }
  }

  @Watch('rescheduleDay')
  onWatchRescheduleDay(value: string): void {
    if(this.beforeClickTimePicker) {
      this.formReshcedule.day = dayjs(this.dataReschedulePickup.rescheduledAt).format('YYYY-MM-DD[T]HH:mm:ss.SSS')
      this.beforeClickTimePicker = false
      return;
    }

    if (value === EnumPickupDay.TODAY) {
      this.formReshcedule.day = dayjs().format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    } else if (value === EnumPickupDay.TOMORROW) {
      this.formReshcedule.day = dayjs().add(1, 'day').format('YYYY-MM-DD[T]HH:mm:ss.SSS')
    }
  }

  @Watch('controller.requestSchedule')
  onWatchErrorRequestSchedule(value: boolean): void {
    if (value) {
      this.$router.push({
        name: 'ReschedulePickupDetail',
        params: { rescheduleId: this.$route.params.rescheduleId },
      })
      controller.setRequestSchedule(false)
    }
  }

  beforeDestroy(): void {
    window.removeEventListener('offline', this.onHandleOffline)
  }
}
